const login = '/login';
const error = '/error';
const program = 'program';
const dashboard = 'dashboard';
const payoutInitialization = 'payout_initialization';
const masterUpload = 'master_file_upload';
const inputFileUpload = 'input_file_upload';
const inputFileDownload = 'input_file_download';
const masterFileDownload = 'master_file_download';
const myTask = 'my_task';
const unificationHistory = 'unification_history';
const payoutHistory = 'payout_history';
const profile = '/profile';
const accounting = 'accounting';
const chartMapping = 'chart_mapping';
const dashboardChartMapping = 'dashboard_chart_mapping';
const chart = 'chart';
const overview = 'overview';
const runSimulation = 'run_simulation';
const viewSimulation = 'view_simulation';
const dataSync = 'data_sync';
const syncManagement = 'sync_management';
const syncHistory = 'sync_history';

const routeName = {
  runSimulation,
  viewSimulation,
  dashboard,
  login,
  error,
  program,
  payoutInitialization,
  masterUpload,
  inputFileUpload,
  inputFileDownload,
  masterFileDownload,
  myTask,
  unificationHistory,
  payoutHistory,
  profile,
  accounting,
  chartMapping,
  dashboardChartMapping,
  chart,
  overview,
  syncManagement,
  syncHistory,
  dataSync,
};

export default routeName;
